/** @jsx jsx */
import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';

import {contactSalesHubspotFormId, getContactSalesFormSchema} from '../../helpers/constants/forms';
import {ContrastContext, ScreenSizeContext} from '../../helpers/context';
import {formPostToHubspot, validateFormData} from '../../helpers/utils/forms';
import SEO from '../../components/common/SEO';
import {Hero, H4, P2} from '../../components/common/Typography';
import {breakpoints, colors, ContentContainer, fontWeights} from '../../styles/theme';
import CircleBackground from '../../components/about/CircleBackground';
import {PrimaryButton} from '../../components/common/Clickables';
import {DefaultForm, FormError, Select} from '../../components/common/Forms';

const settings = require('../../../settings');


/*
 * Constants
 */
const mdBreakpoint = parseInt(breakpoints.md.replace('px', ''), 10);


/*
 * Private Elements
 */
const TalkWithUsContainer = styled.div`
    background-color: ${colors.black};
`;

const TalkWithUsCircleContainer = styled.div`
    padding-bottom: 25px;

    ${CircleBackground} {
        top: 25px;
        right: 25px;
        max-width: none;
        max-height: none;
    }

    ${ContentContainer} {
        position: relative;
        padding-top: 150px;
        padding-left: 25px;
        padding-right: 25px;
        color: ${colors.white};

        ${H4} {
            font-weight: ${fontWeights.regular};
            padding-top: 15px;
        }
    }

    @media (min-width: 500px) {
        ${CircleBackground} {
            right: 30%;
        }
    }

    @media (min-width: 550px) {
        ${CircleBackground} {
            right: 50%;
        }
    }

    @media (min-width: 600px) {
        ${CircleBackground} {
            right: 65%;
        }
    }

    @media not all and (min-width: ${breakpoints.md}) {
        ${CircleBackground} {
            position: absolute;
            border-radius: 50%;
            background: linear-gradient(to top right, ${colors.darkPurple}, ${colors.black});
        }
    }

    @media not all and (min-width: ${breakpoints.lg}) {
        ${ContentContainer} {
            ${Hero} {
                font-size: 34px;
                line-height: 44px;
                font-weight: ${fontWeights.semiBold};
            }

            ${H4} {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 50px;
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 15vh 65px 0;

            ${Hero} {
                margin-top: 10vh;
            }
        }

        ${CircleBackground} {
            right: 42%;
        }

        ${DefaultForm} {
            .form-group {
                margin-bottom: 15px;

                label {
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }
    }

    @media (min-width: 900px) {
        ${DefaultForm} {
            .form-group {
                label {
                    font-size: inherit;
                    line-height: inherit;
                }
            }
        }
    }

    @media (min-width: 1050px) {
        ${DefaultForm} {
            .form-group {
                margin-bottom: 32px;
            }
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        ${ContentContainer} {
            padding-top: 25vh;

            ${Hero} {
                margin-top: 0;
            }
        }
    }

    @media (min-width: ${breakpoints.xl}) {
        ${ContentContainer} {
            padding-top: 35vh;
        }
    }

    @media (min-width: 1800px) {
        ${CircleBackground} {
            right: 45%;
        }
    }
`;

const ContactSalesForm = ({schema}) => {
    const {navigate} = useI18next();

    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formElements = schema.map(input => {
        const id = `contactSalesForm-${input.name}`;
        const required = input.required ? <span className="required">*</span> : null;
        const onChange = event => {
            // Clean error message when the form values changes
            if (formErrors?.message) {
                formErrors.message = null;
            }

            setFormErrors({...formErrors, fields: {...formErrors?.fields, [input.name]: null}});
            setFormData({...formData, [input.name]: input.getValue ? input.getValue(event) : event.target.value});
        };

        const errorMessages = formErrors.fields?.[input.name];
        // eslint-disable-next-line i18next/no-literal-string
        const errorClass = errorMessages && errorMessages.length ? 'error' : '';

        const element = input.element === Select ? (
            <input.element
                className={`form-control ${errorClass}`}
                id={id}
                name={input.name}
                value={formData[input.name] || ''}
                onSelect={onChange}
                disabled={isSubmitting}
                defaultOption={input.attributes.placeholder}
                {...input.attributes}
            />
        ) : (
            <input.element
                className={`form-control ${errorClass}`}
                id={id}
                name={input.name}
                value={formData[input.name] || ''}
                onChange={onChange}
                disabled={isSubmitting}
                {...input.attributes}
            />
        );

        return (
            <div className="form-group" key={input.name}>
                <label htmlFor={id}>
                    {input.label}
                    {required}
                </label>

                {element}

                <FormError messages={errorMessages} />
            </div>
        );
    });

    const submitContactSalesForm = async event => {
        event.preventDefault();
        setIsSubmitting(true);
        setFormErrors({});

        try {
            const frontendErrors = validateFormData(formData, schema);
            if (frontendErrors) {
                setFormErrors(frontendErrors);
                return false;
            }

            const formPostResult = await formPostToHubspot(contactSalesHubspotFormId, formData);
            if (!formPostResult) {
                setFormErrors({
                    message: [<Trans>Couldn&apos;t submit response. Please, try again later.</Trans>],
                });
                return false;
            }
            return navigate('/about/thank-you', {state: {contactEmail: settings.SALES_TEAM_EMAIL}});
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <DefaultForm>
            {formElements}
            <FormError messages={formErrors.message} />
            <div className="submit-group">
                <P2 className="required small"><Trans>*required</Trans></P2>
                <PrimaryButton
                    type="submit"
                    disabled={isSubmitting}
                    onClick={submitContactSalesForm}
                >
                    <Trans>Contact Sales</Trans>
                </PrimaryButton>
            </div>
        </DefaultForm>
    );
};

ContactSalesForm.propTypes = {
    schema: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};


/*
 * Public Elements
 */
const AboutContactSalesPage = () => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const screenSize = useContext(ScreenSizeContext);
    const {t} = useTranslation();
    const [contentContainerHeight, setContentContainerHeight] = useState(0);
    const contentContainer = useRef(null);
    let circleCss = null;

    // Update the stored content container height on resize
    useEffect(() => {
        // Only update the state if the screen is smaller than medium, to avoid unecessary re-renders
        if (screenSize.width < mdBreakpoint) {
            setContentContainerHeight(contentContainer.current.scrollHeight);
        }
    }, [screenSize.width]);

    if (screenSize.width < mdBreakpoint) {
        // Calculate the diameter of the circle, using the chord's length as the content container height, and width as
        // 95% of the screen size.
        // eslint-disable-next-line max-len
        // More info: https://math.stackexchange.com/questions/564058/calculate-the-radius-of-a-circle-given-the-chord-length-and-height-of-a-segment/1176742#1176742
        const halfCord = contentContainerHeight / 2;
        const width = 0.95 * screenSize.width;

        const radius = ((halfCord ** 2) + (width ** 2)) / (2 * width);
        const diameter = 2 * radius;
        circleCss = {
            height: diameter,
            width: diameter,
        };
    }

    const contactSalesFormSchema = getContactSalesFormSchema(t);

    return (
        <>
            <SEO
                title={t('Contact Sales')}
                ogTitle={t('Contact Sales - Switch')}
                description={t('Switch works with merchants, acquirers, ISVs, and PSPs to provide added flexibility in '
                    + 'payments via a single PCI compliant platform.')}
                image="/website/metatags/about-contact.png"
            />

            <TalkWithUsContainer>
                <TalkWithUsCircleContainer>
                    <CircleBackground css={circleCss} />

                    <ContentContainer ref={contentContainer}>
                        <div className="row no-gutters">
                            <div className="col-12 col-md-5">
                                <Hero><Trans>We are here to help you.</Trans></Hero>

                                <H4>
                                    <Trans>
                                        Our sales team will gladly assist you with any questions. Send us a message and
                                        we will get back to you shortly.
                                    </Trans>
                                </H4>
                            </div>
                            <div className="col-12 col-md-5 offset-md-2 col-xl-4">
                                <ContactSalesForm schema={contactSalesFormSchema} />
                            </div>
                        </div>
                    </ContentContainer>
                </TalkWithUsCircleContainer>
            </TalkWithUsContainer>
        </>
    );
};


/*
 * Exports
 */
export default AboutContactSalesPage;
