import {useStaticQuery, graphql} from 'gatsby';

const useSiteMetadata = () => {
    const {site} = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    defaultTitle: title
                    titleTemplate
                    defaultDescription: description
                    defaultImage: image
                    author
                    siteUrl
                }
            }
        }
    `);
    return site.siteMetadata;
};

export default useSiteMetadata;
