/* eslint-disable i18next/no-literal-string */
import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'gatsby-plugin-react-i18next';
import {useLocation} from '@gatsbyjs/reach-router';
import useSiteMetadata from '../../helpers/hooks/useSiteMetaData';


function SEO({title, ogTitle = null, description, image = null, isArticle = false, lang = 'en'}) {
    const {pathname} = useLocation();
    const siteMetadata = useSiteMetadata();

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        defaultImage,
        siteUrl,
    } = siteMetadata;

    const seo = {
        title: title || defaultTitle,
        ogTitle: ogTitle || title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
    };

    // Since Twitter also looks for Open Graph (Facebook) tags instead of only its own, we can avoid duplicated tags
    // See: https://css-tricks.com/essential-meta-tags-social-media/#reconciling-meta-tags
    return (
        <Helmet
            title={seo.title}
            titleTemplate={titleTemplate}
        >
            <html lang={lang} />

            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            {seo.ogTitle && <meta property="og:title" content={seo.ogTitle} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.image && <meta name="twitter:card" content="summary_large_image" />}
            {isArticle && <meta property="og:type" content="article" />}
        </Helmet>
    );
}

SEO.defaultProps = {
    ogTitle: null,
    description: null,
    lang: 'en',
    image: null,
    isArticle: false,
};

SEO.propTypes = {
    title: PropTypes.string.isRequired,
    ogTitle: PropTypes.string,
    description: PropTypes.string,
    lang: PropTypes.string,
    image: PropTypes.string,
    isArticle: PropTypes.bool,
};


export default SEO;
