import styled from '@emotion/styled';

import {colors, maxWidth} from '../../styles/theme';
import circle from '../../images/about/circle.png';


/*
 * Constants
 */
const circleImageRatio = 0.692;  // Ratio of the circle, to help us calculate its height based on the screen width
const circleHeightMultiplier = circleImageRatio * 0.95;  // The circle can only use 95% of the total screen width


/*
 * Public Elements
 */
const CircleBackground = styled.div`
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background: ${colors.transparent} url(${circle}) no-repeat scroll center bottom;
    background-size: contain;
    max-width: ${maxWidth}px;
    height: calc(100vw * ${circleHeightMultiplier});
    max-height: calc(${maxWidth}px * ${circleHeightMultiplier});
`;


/*
 * Exports
 */
export default CircleBackground;
